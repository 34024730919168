import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import TeamDetailInfo from "../components/team-detail-info/team-detail-info"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import Layout from "../components/layout"
import HomeBanner from "../components/home-banner/home-banner"
import logoBlack from "../images/svg/logo.svg"
import NotFoundPage from "../pages/404"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { useStaticQuery, graphql } from "gatsby"
import TeamBanner from "../components/home-banner/team-banner"
import OtherNewsSales from "../components/news-sales/other-news-sales"
import BranchGuideIntro from "../components/branch-guide-intro/branch-guide-intro"
import BranchProperties from "../components/home-properties/branch-properties"
import AreaGuideSlider from "../components/home-properties/area-guide-slide"
import BranchInfo from "../components/property-info/branch-info"
import BranchNewsSales from "../components/news-sales/branch-news-sales"
import { toCapitalize } from "@components/common/utils";
import { useLocation } from "@reach/router";

const OfficeDetail = (props) => {
  
  const GET_OFFICE_DETAILS = gql`
  query GetOffice($URL: String!) {
    offices(where: { URL: $URL }) {
      id
      imagetransforms
      Office_Name
      Office_Image {
        url
        alternativeText
      }
      Office_Email
      Office_Address
      URL
     Choose_Area_Guide{
      id
        imagetransforms
       Title
       Tile_Image{
         url
         alternativeText
       }
       URL
       Video_Url
     }
      Opening_Hours
      Office_Telephone_Sales
      Office_Telephone_Lettings
      Office_Telephone_Progression
      Property_Management_number
      About_Content
      Gallery_Images {
        id
        Image {
          url
          alternativeText
        }
      }
      Latitude
      Longitude
     
    }
  }
`
  const GET_TEAM_DETAILS = gql`
    query MyQuery($URL: String!) {
      offices(where: { URL: $URL }) {
        Office_Name
        id
          Choose_Team_Members {
            id
            imagetransforms
            Name
            Email
            Designation
            Video_Url
            URL
            Image {
              alternativeText
              url
            }
          }
        }
      }
    `
    const CUSTOM_TEAM_DETAILS = gql`
      query MyQuery($URL: String!) {
        offices(where: { URL: $URL }) {
            Choose_Team_Members(sort:"Sort_By_Order:ASC", where: {Publish: true}) {
              id
              imagetransforms
              Name
              Email
              Designation
              Video_Url
              URL
              Image {
                alternativeText
                url
              }
            }
          }
        }
      `
  const { loading_, error_, data: team_Data } = useQuery(GET_TEAM_DETAILS, {
    variables: { URL: props.slug },
    fetchPolicy: "no-cache"
  })

  const { _loading, _error, data: custom_team_Data } = useQuery(CUSTOM_TEAM_DETAILS, {
    variables: { URL: props.slug },
    fetchPolicy: "no-cache"
  })

  const location = useLocation()
  const pathName = location?.pathname?.split("/")[3];
  const chippenham_Offie = pathName?.includes('chippenham') ? true : false;

  const { loading, error, data } = useQuery(GET_OFFICE_DETAILS, {
    variables: { URL: props.slug },
    fetchPolicy: "no-cache"
  })
  // debugger
  // console.log(data)
  let shareurl = ""
  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : ""
  }
  if (loading)
  return (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading">
          <img
            className="logo-white loader-logo"
            src={logoBlack}
            alt="logo"
          />
        </div>
      </Container>
    </div>
  )

  return !loading && data?.offices && data?.offices?.length > 0 ? (
    <Layout
    isTspPage={true}
    searchtype={"office"}
    area={data?.offices[0]?.Office_Name}
    >
      {data?.offices?.map((Page, i) => {
        let processedImages = JSON.stringify({})
        if (Page?.imagetransforms?.Office_Image_Transforms) {
          processedImages = Page.imagetransforms.Office_Image_Transforms
        }
        const path = {
          pathname: `/about/${props?.alias}/${props?.slug}/`
        }
        return (
          <>
            <Seo
              title={
                Page ? `Estate agents in ${toCapitalize(Page?.Office_Name)}` : ""
              }
              location={path}
              description={
                Page
                  ? 
                  `Our Estate agents in ${toCapitalize(Page?.Office_Name)} offer the best property advice on Selling, Buying, Letting and Renting your property. Contact us to get assistance.`
                  : ""
              }
              image={Page?.Office_Image?.url ? Page.Office_Image.url : null}
            />
            <Helmet
              bodyAttributes={{
                class: `templates-blog-details-template our-branches-page`,
              }}
            />

           
            <BranchGuideIntro
            Office_Email={Page?.Office_Email}
            Office_Telephone_Sales={Page?.Office_Telephone_Sales}
            Office_Telephone_Lettings={Page?.Office_Telephone_Lettings}
            Office_Telephone_Progression={Page?.Office_Telephone_Progression}
            Property_Management_number={Page?.Property_Management_number}
             Pagename={Page?.Office_Name}
             Alias={Page?.URL}
             Banner_Image={Page?.Office_Image}
             // Banner_Video={Page?.Banner_Video}
             imagetransforms={processedImages}
             id={Page?.id}
             Banner_Content={Page?.Office_Address}
             Cta_Links={[
              {
                // "Label": `Contact ${Page?.Office_Name}`,
                "Label": `Contact Branch`,
                "Icon_Name": "arrow-up-right",
                "Link": "",
                
              },
               {
                 "Label": "Book a Valuation",
                 "Icon_Name": "house-line",
                 "Url": {
                   "Alias": "property-valuation-in-wiltshire",
                 }
               }
             ]}
             Banner_Title={Page?.Office_Name === "auctions" || Page?.Office_Name === "Vale of Pewsey" || Page?.Office_Name === "sales progression" || Page?.Office_Name === "lettings head office" || Page?.Office_Name === "property management" || Page?.Office_Name === "formal valuations & advice" ? Page?.Office_Name : Page?.Office_Name+" branch"}
             imgname={"office.Office_Image.bannerimg"}
            />
             <Breadcrumbs Page={toCapitalize(Page?.Office_Name)} alias={"our-branches"} type={"details-page"} />
             <BranchInfo data={Page}
              />
              { chippenham_Offie !=true ?
                <BranchNewsSales teams={team_Data?.offices[0]?.Choose_Team_Members} officename={Page?.Office_Name} />
              :
                <BranchNewsSales teams={custom_team_Data?.offices[0]?.Choose_Team_Members} officename={Page?.Office_Name}/>
              }
             <BranchProperties area={Page?.Office_Name}
             Title={`Properties in ${Page?.Office_Name}`}
             Cap_Title={"Be inspired"}
             />
             <div className="border-bottom"></div>
             {Page?.Choose_Area_Guide?.length > 0 &&
             <AreaGuideSlider 
             Title={`We’ll help you find a perfect place`}
             Cap_Title={`Area Guides`}
             
             data={Page?.Choose_Area_Guide} />
      }
            {/* <TeamDetailInfo data={Page} />
            <OtherNewsSales URL={Page?.URL} />
                  <TeamBanner
                  Banner_block={banner?.glstrapi?.contact?.Team_Banner_Cta}
/> */}
                    
          </>
        )
      })}
    </Layout>
  ) : (
    <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
  )

}

export default OfficeDetail

